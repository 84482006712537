:root {
    --site-membership-badge-background-colour: #000;
    --site-membership-badge-text-colour: #fff;
}

.Registeraccounticons {
    font-size: 1.6em;
}

.cancelWrap {
    text-align: right;
}

.Login .innerPass {
    max-width: 900px;
    margin: 0 auto;
    border: 1px solid #dedede;
    padding: 20px;
}

.Login .headerPass {
    text-align: center;
}

.Login .passtext {
    font-size: 1.2em;
}

.ReactivateRequest p,
.ReactivateRequest li {
    font-size: 1.2em;
}

.ReactivateRequest p a,
.ReactivateRequest li a {
    text-decoration: underline;
}

.ReactivateRequest ul,
.ReactivateRequest ol {
    padding-left: 20px;
}

.ReactivateRequest ul li {
    list-style-type: disc;
}

.ReactivateRequest ol li {
    list-style-type: decimal;
}

.ReactivateRequest h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0;
}

.ReactivateRequest h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0;
}

.Login .lowerPass {
    max-width: 400px;
    margin: 30px auto;
    padding: 0 20px;
}

.Login .passButtons > div {
    margin: 10px 0;
}

.Login .passButtons .cancelWrap {
    margin: 20px 0 0;
}

.Login .passButtons .ImgButWrap {
    width: 100%;
}

.Login .cancelWrap .ImgButWrap a {
    background-color: #f5f5f5 !important;
    border: 1px solid #e9e9e9;
    color: #535353 !important;
    font-weight: bold;
}

.Login .cancelWrap .ImgButWrap a:hover {
    background-color: #fafafa !important;
}

.Login .newCustomer .innerBorder {
    background-color: #f5f5f5;
}

.AdminLogin.Login .ModLoginScreenC {
    max-width: 600px;
    margin: 0 auto;
}

.Login .loginContainer {
    min-height: 204px;
}

.Login .newlogin .myAccLogin .row {
    padding: 5px 0;
}

.Login .newlogin .myAccLogin .logText {
    width: calc(100% - 24px);
    font-size: 1.2em;
    line-height: 22px;
}

.Login .newlogin .myAccLogin .logIcon {
    height: 22px;
    width: 24px;
    vertical-align: top;
    background-image: url("/images/core/global-sprite-sd-v4.svg");
    background-size: 750px 750px;
    background-position: -11px -188px;
}

.loginContainer .SignLogIn2,
.OrderCompeleteRegistrationForm .field {
    margin-bottom: 25px;
}

.recognisedLoginForm .InputLabelGroup > div:first-of-type {
    display: none;
}

.Login .membership-enabled > .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "existingCustomer newCustomer"
        "tcLogin tcLogin";
    gap: 0 24px;
    margin: 0;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "existingCustomer"
            "newCustomer"
            "tcLogin";
        gap: 32px 0;
    }

    .existingCustomer {
        grid-area: existingCustomer;
    }

    .newCustomer {
        grid-area: newCustomer;
    }

    .existingCustomer,
    .newCustomer {
        width: 100%;
        padding: 0;
        border: none;
    }

    .tcLogin {
        grid-area: tcLogin;
    }
}

.Login .newlogin.membership-enabled {
    h1 {
        text-transform: uppercase;
        padding: 0 0 32px;
        text-align: left;
    }

    label {
        font-size: var(--body-font-size, 14px);
        font-weight: var(--fw-normal, 400);
        min-height: 0;
    }

    .form-control {
        max-width: 100%;
        height: 45px;
        border-radius: 4px;
    }

    .innerBorder {
        background-color: #f8f8f8;
        border: none;
        padding: 16px;
        height: 100%;

        .ImgButWrap {
            max-width: 312px;
            border-radius: 4px;

            @media (max-width: 767px) {
                max-width: 100%;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                height: 48px;
                padding: 0;
                text-transform: uppercase;

                &::after {
                    display: none;
                }
            }
        }
    }

    .loginContainer {
        display: flex;
        flex-direction: column;

        .NewCustWrap {
            position: static;
            order: 1;
            margin: 0;
        }

        .SignLogIn2 {
            margin-bottom: 0;

            &:first-of-type {
                margin-bottom: 32px;
            }
        }

        .ForgotPass {
            margin: 16px 0 24px;
            padding: 0;
            text-align: left;
        }
    }

    .membership-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        margin-bottom: 16px;

        .global-site-logo svg {
            max-width: 175px;
        }
    }

    .myAccLogin {
        .list-top,
        .list-bottom {
            margin-bottom: 32px;

            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
        }

        .list-item {
            display: flex;
            margin-bottom: 16px;
        }

        .logIcon {
            height: 20px;
            background: none;

            svg .tick-outer {
                fill: transparent;
            }
        }
    }

    .myAccLogin .logText,
    .memberText {
        font-size: var(--body-font-size, 14px);
        line-height: 20px;
    }
}

@media (min-width: 768px) {
    .newCustomer .loginContainer .SignLogIn2 {
        margin: 0;
    }

    .Login .existingCustomer > div {
        margin-right: 25px;
    }

    .Login .innerBorder {
        position: relative;
        padding-bottom: 90px;
    }

    .Login .newCustomer > div {
        margin-left: 25px;
        margin-right: 0;
    }

    .Login .membership-enabled .newCustomer > div {
        margin-left: 0;
    }

    .NewCustWrap {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

@media (max-width: 767px) {
    .ContentWrapper.container-fluid {
        padding: 10px 0 15px;
    }

    .Login .innerPass {
        border-left: 0;
        border-right: 0;
        border-top: 0;
        padding: 0 0 20px;
    }

    .Login .passtext {
        padding: 0 20px;
    }

    .Login .tcLogin {
        text-align: center;
        padding: 20px 25px;
    }

    .Login .newCustomer {
        background-color: #f5f5f5;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
    }

    .newlogin .dnnFormMessage {
        padding: 10px 20px;
        text-align: center;
    }

    .Login .newCustomer .innerBorder {
        padding-bottom: 30px;
    }

    .Login h1 {
        text-align: center;
        border: 0;
    }

    .Login h2 {
        text-align: center;
        border: 0;
    }

    .Login .newCustomer h1 {
        padding-top: 30px;
    }

    .Login .newCustomer .NewCustWrap {
        margin-top: 20px;
    }

    .CancelResponsive {
        text-align: center;
        margin-top: 15px;
    }

    .chgplabel {
        text-align: left;
        width: 100%;
    }

    .chgpasswordwrap .field.area {
        margin: 12px 0;
    }

    .Passfield .label {
        text-align: left;
    }

    .passbutton .ImgButWrap,
    .passbutton .ImgButWrap a,
    #chgpassworddone .ImgButWrap,
    #chgpassworddone .ImgButWrap a {
        width: 100%;
    }

    .passbutton .ImgButWrap {
        margin-bottom: 10px;
    }
}
